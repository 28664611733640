import "./index.scss";

//common
// import "../modules/common/refsCommon";
// import '../modules/common/header';

// swiper
// import '../modules/common/whatWeDoSwiper'

// accordion
// import "../modules/common/accordion";

// modules
import "../modules/common/forms";
import "../modules/common/other";

// animate
// import '../modules/common/aos'

//modals
// import "../modules/modals/indexHystModal";
